import React from 'react'
import PropTypes from 'prop-types'
import { Text } from '@chakra-ui/core'

function Subtle ({ children }) {
  return (
    <Text as="span" color="gray.300">
      {children}
    </Text>
  )
}

Subtle.propTypes = {
  children: PropTypes.node.isRequired
}

export default Subtle
