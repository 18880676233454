import React from 'react'
import Layout from 'elements/Frame/Layout'
import Title from 'elements/Typography/Title'
import Subtle from 'elements/Typography/Subtle'
import Link from 'elements/Link/Link'
import { Text } from '@chakra-ui/core'
import { DOCS_ROUTE, FORUM_ROUTE } from 'config/routes'

export default function WelcomePage () {
  return (
    <Layout>

      <Title>Welcome! <Subtle>Let&apos;s get started</Subtle></Title>
      <Text>
      Thanks for joining! Check out the <Link styled to={DOCS_ROUTE}>documentation</Link> to get started.
      </Text>
      <Text>If you&apos;d like to chat with us you can often find us in our <Link styled title="Join our discussion" external to={FORUM_ROUTE}>discussion forum</Link>.</Text>

    </Layout>)
}
